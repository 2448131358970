import axios from 'axios';
import { URL_PREFIX } from './variables';

export const getFavorites = (...rest) =>
    axios.get(`${URL_PREFIX}/favorite`, ...rest);
export const postFavorite = (...rest) =>
    axios.post(`${URL_PREFIX}/favorite`, ...rest);
export const getFavorite = (id, ...rest) =>
    axios.get(`${URL_PREFIX}/favorite/${id}`, ...rest);
export const deleteFavorite = (id, ...rest) =>
    axios.delete(`${URL_PREFIX}/favorite/${id}`, ...rest);

export default {
    getFavorites,
    postFavorite,
    getFavorite,
    deleteFavorite,
};
