<template>
  <AppCard hoverable class="product-list-card p-3">
    <!-- Image -->
    <AppMediaViewer
      :src="thumbnail"
      class="product-list-card-img mb-2 w-100"
      aspectRatio="1/1"
      @click="viewProduct"
    />
    <!-- Product Name-->
    <h5
      class="
        text-body-3 text-md-headline-5 text-uppercase
        font-weight-bold
        text-truncate
      "
    >
      {{ name }}
    </h5>
    <!-- Product Price-->
    <h5 class="text-body-3 text-md-headline-5 text-uppercase text-gray">
      {{ price }}
    </h5>
    <div class="row">
      <div class="col-12 col-md-6">
        <!-- Product Stocks -->
        <div v-if="stocks !== false">
          <label
            v-if="stocks > 0"
            class="text-caption-2 text-md-label text-success-light-2"
          >
            {{ stocks }} pcs in stock
          </label>
          <label
            v-else
            class="text-caption-2 text-md-label text-danger-light-2"
          >
            Out of stock
          </label>
        </div>
      </div>
      <div class="col-12 col-md-6" v-if="product_id">
        <AppBtn
          text
          :color="isFavorite ? 'danger' : 'black'"
          class="float-right"
          prependIcon="favorite"
          @click="saveFavorite()"
        ></AppBtn>
      </div>
    </div>
  </AppCard>
</template>

<script>
import fileExtensionChecker from '@/shared/utils/fileExtensionChecker';
import AppMediaViewer from '../../../shared/elements/AppMediaViewer.vue';
import AppCard from '../../../shared/elements/AppCard.vue';
import AppBtn from '@/shared/elements/AppBtn.vue';
import Favorites from '@/shared/api/Favorites';

export default {
  components: { AppMediaViewer, AppCard, AppBtn },
  name: 'ProductListCard',

  props: {
    thumbnail: { type: String, default: '' },
    name: { type: String, default: '' },
    price: { type: String, default: '' },
    stocks: { type: [String, Number, Boolean], default: false },
    favorite: { type: [Boolean], default: false },
    product_id: { type: [Number], default: 0 },
  },

  data() {
    return {
      isFavorite: this.favorite,
    };
  },

  methods: {
    ...fileExtensionChecker,

    async saveFavorite() {
      if (!this.isFavorite) {
        await Favorites.postFavorite({
          product_id: this.product_id,
        });
        this.isFavorite = true;
      } else {
        await Favorites.deleteFavorite(this.product_id);
        this.isFavorite = false;
      }
    },

    viewProduct() {
      if (this.product_id) {
        this.$router.push({
          name: 'ProductView',
          params: { id: this.product_id },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/theme/_shadows';
@import '@/scss/theme/_colors';

.product-list-card {
  // cursor: pointer;
  // transition: background-color 0.15s, box-shadow 0.15s;
  &:hover {
    // @extend .shadow-80;
    // @extend .bg-gray-light-4;
    // .product-list-card {
    //   // &-img,
    //   // &-video,
    //   // &-placeholder {
    //   //   // transform: scale(103%);
    //   // }
    // }
  }

  &-placeholder {
    filter: saturate(50%);
  }
}
</style>
